import gql from 'graphql-tag';

export const paymentAbilityCheckDataFragment = gql`
  fragment paymentAbilityCheckData on PaymentAbilityCheckData {
    annualIncome
    dependents
    rentOrMortgage
  }
`;

export const plusstatusFragment = gql`
  fragment plusstatus on PlusStatus {
    ekycStatus
    houkatsuStatus
    appleStatus
  }
`;

export const eKycConsumerDataFragment = gql`
  fragment eKycConsumerData on EKycConsumerData {
    firstNameKanji
    lastNameKanji
    firstNameKana
    lastNameKana
    birthDate
    occupation
    address {
      line1
      line2
      city
      state
      zip
    }
    applicationReason
    updatedAt
    metadata {
      liquidApplicationId
    }
  }
`;

export default gql`
  ${plusstatusFragment}
  ${paymentAbilityCheckDataFragment}
  ${eKycConsumerDataFragment}
  query ConsumerPage($consumerId: ID!) {
    consumer(id: $consumerId) {
      id
      status
      dateOfBirth
      kanaName
      kanjiName
      email
      phone
      createdAt
      address {
        line1
        line2
        city
        state
        zip
      }
      closedAt
      closedBy
      linkToMixPanel
      legalStatus
    }
    plusConsumerData(consumerId: $consumerId) {
      appleLineOfCreditLimit
      pacData {
        ...paymentAbilityCheckData
      }
      spendLimit {
        max
        selected
      }
      eKycConsumerData {
        ...eKycConsumerData
      }
      plusConsumerDetails {
        nameKanji
        nameKana
        address {
          line1
          line2
          city
          state
          zip
        }
      }
    }
    plusstatus(consumerId: $consumerId) {
      ...plusstatus
    }
    payments(consumerId: $consumerId) {
      shippingAddress {
        line1
        line2
        city
        state
        zip
      }
      billingAddress {
        zip
        line1
        line2
        state
        city
      }
      id
      consumerName
      amount
      capturedAmount
      refundedAmount
      merchantId
      captures {
        id
      }
      status
      tags
      createdAt
      test
      merchantName
    }
    activeFraudFlagsByConsumerId(consumerId: $consumerId) {
      id
    }
    assessmentExclusionsByConsumerId(consumerId: $consumerId) {
      assessmentExclusions {
        id
      }
      error {
        kind
      }
    }
  }
`;

export const labelMap: { [key: string]: string } = {
  annualIncome: '年収',
  firstName: '名',
  lastName: '姓',
  dateOfBirth: '生年月日',
  gender: '性別',
  maritalStatus: '配偶者有無',
  householdSize: '同居人数',
  mortgage: '家賃・住宅ローン有無',
  employer: '会社名',
  contractAddress: '住所',
  residenceType: '住居形態',
  createdAt: '作成日',
  updatedAt: '更新日',
  amount: '金額',
  id: 'ID',
  reason: '理由',
  currency: '通貨',
  captureId: 'キャプチャーID',
  captures: 'キャプチャー',
  capturedAmount: 'キャプチャー金額',
  status: 'ステータス',
  tier: '種類',
  description: '詳細',
  consumerId: '顧客ID',
  consumerName: '氏名',
  merchantName: '加盟店名',
  refunds: 'リファンド',
  refundedAmount: 'リファンド',
  contractData: '契約内容',
  address: '住所',
  shippingAddress: '住所',
  billingAddress: '請求先住所',
  tags: 'タグ',
  name: '氏名',
  email: 'Eメール',
  phone: '電話番号',
  origin: '作成者',
  order: 'オーダー',
  tax: '税金',
  shipping: '送料',
  orderRef: 'オーダー番号',
  items: '商品名',
  title: 'タイトル',
  quantity: '数量',
  basketId: 'バスケットID',
  appleOrderNumber: 'Apple Order ID',
  unitPrice: '単価',
  expiresAt: '有効期限',
  buyerDataJson: 'その他',
  name1: '漢字姓名',
  name2: 'カナ姓名',
  consumerData: '顧客データ',
  buyer: '購入者',
};

export const cardStatus = {
  active: 'active',
  destroyed: 'destroyed',
  frozenByAdmin: 'frozenByAdmin',
  frozenByConsumer: 'frozenByConsumer',
};

export const cardStatusMap: { [key: string]: string } = {
  active: '00: ALL GOOD',
  destroyed: '83: Card Destroyed',
  frozenByAdmin: '99: Card Voided',
  frozenByConsumer: 'G1: Temporary Blocked by consumer',
};

export const NUM_ITEMS_PER_PAGE = 10;

export const API_HOST_PRODUCTION =
  'https://apis.paidy.com/paidy/admin/consumers/api/v0.0.1';
export const API_HOST_STAGING =
  'https://apis.paidy-staging.com/paidy/admin/consumers/api/v0.0.1';

export const API_HOST =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? API_HOST_PRODUCTION
    : API_HOST_STAGING;

export const MYPAIDY_HOST_PRODUCTION = 'https://my.paidy.com';
export const MYPAIDY_HOST_STAGING = 'https://my.paidy-staging.com';

export const MYPAIDY_HOST =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? MYPAIDY_HOST_PRODUCTION
    : MYPAIDY_HOST_STAGING;

export const MESSAGE_TIMEOUT = 3000;
export const ERROR_MESSAGE_TIMEOUT = 5000;
export const IMPERSONATE_SESSION_TIMEOUT = 1500000; // Timeout in 3 mins, refresh token at 2.5 mins

export const MAX_NUM_INSTALLMENT_PLAN_ID = 25;

export const CHARGE_KIND = {
  Settlement: 'Settlement',
  Cashback: 'Cashback',
  Refund: 'Refund',
  MultiPayDebit: 'MultiPayDebit',
  ManualDebit: 'ManualDebit',
  Capture: 'Capture',
  LateFee: 'LateFee',
  MultiPayCredit: 'MultiPayCredit',
  ManualCredit: 'ManualCredit',
};

export const LEGAL_STATUS = {
  Normal: 'Normal',
  LawyerInterventionAssigned: 'LawyerInterventionAssigned',
  BankruptcyStart: 'BankruptcyStart',
  CivilRehabilitationStart: 'CivilRehabilitationStart',
  Reconciliation: 'Reconciliation',
  Dead: 'Dead',
  LawyerInterventionUnassigned: 'LawyerInterventionUnassigned',
  BankruptcyConfirmed: 'BankruptcyConfirmed',
  CivilRehabilitationConfirmed: 'CivilRehabilitationConfirmed',
  FailureOfReconciliation: 'FailureOfReconciliation',
  CollectionPending: 'CollectionPending',
};

export const LEGAL_STATUS_TEXT = {
  Normal: '通常',
  LawyerInterventionAssigned: '弁護介入受任',
  BankruptcyStart: '破産開始',
  CivilRehabilitationStart: '民事再生開始',
  Reconciliation: '和解',
  Dead: '死亡',
  LawyerInterventionUnassigned: '弁護介入辞任',
  BankruptcyConfirmed: '破産決定',
  CivilRehabilitationConfirmed: '民事再生決定',
  FailureOfReconciliation: '和解不履行',
  CollectionPending: '督促一時停止',
};

import { useMutation } from '@apollo/react-hooks';
import cx from 'classnames';
import React, { SyntheticEvent } from 'react';
import { showNotification } from '../../../utils';
import Modal from '../../Modal/Modal';
import modalStyles from '../../Modal/Modal.module.scss';
import styles from './DisableConsumerModal.module.scss';
import { disableConsumer } from './gql-query';

type DisableConsumerModalProps = {
  isOpen: boolean;
  setIsOpen: Function;
  consumerId: string;
  setUpdatedStatus: Function;
  kanaName: string;
  kanjiName: string;
  dateOfBirth: string;
  email: string;
};

const useDisableConsumer = (
  id: string,
  displayDisableNoticeToConsumer: boolean,
  onCompleted: () => void
) => {
  return useMutation(disableConsumer, {
    onCompleted,
    onError: () => {
      showNotification('Something went wrong', 'error');
    },
    variables: { id, displayDisableNoticeToConsumer },
    refetchQueries: ['ConsumerPage'],
  });
};

const EnableAccountModal = ({
  isOpen,
  setIsOpen,
  consumerId,
  kanaName,
  kanjiName,
  dateOfBirth,
  email,
}: DisableConsumerModalProps) => {
  const onCompleted = () => {
    setIsOpen(false);
    showNotification('The account has been disabled', 'success');
  };
  const onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate();
  };

  const [mutate, { loading }] = useDisableConsumer(
    consumerId,
    true,
    onCompleted
  );

  return (
    <Modal isOpen={isOpen}>
      <h2>アカウントをDisable</h2>
      <form onSubmit={onSubmit}>
        <section className={styles['info-section']}>
          <div className={styles.extendable}>
            <div className={styles.label}>
              {kanaName || 'カナ名がありません'}
            </div>
            <div className={cx(styles.value, styles.name)}>{kanjiName}</div>
          </div>
          <div>
            <div className={styles.label}>生年月日</div>
            <div className={cx(styles.value, styles.dob)}>
              {dateOfBirth || 'Empty'}
            </div>
          </div>
          <div>
            <div className={styles.label}>メール</div>
            <div className={cx(styles.value, styles.email)}>{email}</div>
          </div>
        </section>

        <div className={styles.important}>
          <em>注意: </em>
          アカウントをDisableにすると、顧客はチェックアウトできなくなります。
        </div>

        <div className={modalStyles.actions}>
          <input
            type='submit'
            className='btn red'
            value='Disableする'
            data-testid='submit'
            disabled={loading}
          />
          <input
            type='button'
            className='btn'
            value='キャンセル'
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

export default EnableAccountModal;

import { CsvRecord, ErrorType } from '../ReportSettingTabContent';

function getDeactivatedDate(deactivatedDate: string) {
  const year = parseInt(deactivatedDate.substring(0, 4), 10);
  const month = parseInt(deactivatedDate.substring(4, 6), 10);

  return {
    year,
    month,
  };
}

function validateHeader(header: string[]): ErrorType | null {
  if (
    header.length !== 2 ||
    header[0] !== 'Deactivated_Month' ||
    header[1] !== 'Merchant_ID'
  ) {
    return {
      message: `Please check the document header`,
      for: 'header',
      value: '',
    };
  }
  return null;
}

function validateMerchantID(merchantId: string): ErrorType | null {
  if (merchantId.length !== 20 || /^(?!mer_[-\w\d]+$)/.test(merchantId)) {
    return { message: 'Please check the Merchant ID again', value: merchantId };
  }
  return null;
}

function validateDeactivatedDate(
  deactivatedDate: string,
  merchantId: string
): ErrorType | null {
  const monthRegex = /^\d{4}(0[1-9]|1[0-2])$/;
  if (!monthRegex.test(deactivatedDate)) {
    return {
      message: 'Please check the deactivated Month again, it is not numeric',
      value: merchantId,
    };
  }

  const { year, month } = getDeactivatedDate(deactivatedDate);

  if (
    year < 2000 ||
    year > new Date().getFullYear() ||
    month < 1 ||
    month > 12
  ) {
    return {
      message: 'Please check the deactivated Month again, it is not valid date',
      value: merchantId,
    };
  }
  return null;
}

function isEmptyOrIncorrectColumns(line: string[]): boolean {
  return (line.length === 1 && line[0] === '') || line.length !== 2;
}

function parseCsvLine(line: string): CsvRecord {
  const parts = line.split(',').map((item) => item.trim());
  const [deactivatedMonth, merchantId] = parts;
  const { year, month } = getDeactivatedDate(deactivatedMonth);

  return {
    merchantId,
    year: year,
    month: month,
  };
}

function validateCsvData(lines: string[]): ErrorType[] {
  if (lines.length === 0) {
    return [
      { message: 'The CSV file is empty', for: 'selected file', value: '' },
    ];
  }

  const errors = [];

  const headerError = validateHeader(
    lines[0].split(',').map((item) => item.trim())
  );
  if (headerError) {
    errors.push(headerError);
  }

  for (let i = 1; i < lines.length; i++) {
    const line = lines[i].split(',').map((item) => item.trim());
    const [deactivatedMonth, merchantId] = line;

    if (isEmptyOrIncorrectColumns(line)) {
      errors.push({
        message: `Incorrect number of columns or empty line`,
        value: merchantId,
      });
    }

    const merchantIdError = validateMerchantID(merchantId);
    if (merchantIdError) {
      errors.push({ ...merchantIdError });
    }

    const deactivatedDateError = validateDeactivatedDate(
      deactivatedMonth,
      merchantId
    );
    if (deactivatedDateError) {
      errors.push({ ...deactivatedDateError });
    }
  }

  return errors;
}

export { parseCsvLine, validateCsvData };

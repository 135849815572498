import { useMutation } from '@apollo/react-hooks';
import cx from 'classnames';
import React, { SyntheticEvent, useState } from 'react';
import { LEGAL_STATUS, LEGAL_STATUS_TEXT } from '../../../constants';
import { showNotification } from '../../../utils';
import Modal from '../../Modal/Modal';
import modalStyles from '../../Modal/Modal.module.scss';
import { updateConsumerLegalStatus } from './gql-query';
import styles from './UpdateLegalStatusModal.module.scss';

type UpdateLegalStatusModalProps = {
  isOpen: boolean;
  setIsOpen: Function;
  consumerId: string;
  setUpdatedStatus: Function;
  kanaName: string;
  kanjiName: string;
  dateOfBirth: string;
  email: string;
  status: string;
};

const useUpdateLegalStatus = (
  id: string,
  legalStatus: string,
  onCompleted: () => void
) => {
  return useMutation(updateConsumerLegalStatus, {
    onCompleted,
    onError: () => {
      showNotification('Something went wrong', 'error');
    },
    variables: { id, legalStatus },
    refetchQueries: ['ConsumerPage'],
  });
};

const UpdateLegalStatusModal = ({
  isOpen,
  setIsOpen,
  consumerId,
  kanaName,
  kanjiName,
  dateOfBirth,
  email,
  status,
}: UpdateLegalStatusModalProps) => {
  const [legalStatus, setLegalStatus] = useState(status);

  const onCompleted = () => {
    setIsOpen(false);
    showNotification('The legal status has been updated', 'success');
  };

  const onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate();
  };

  const [mutate, { loading }] = useUpdateLegalStatus(
    consumerId,
    legalStatus,
    onCompleted
  );

  const LegalStatus = ({ label, value }: { label: string; value: string }) => {
    const isDisabled = [
      LEGAL_STATUS.Dead,
      LEGAL_STATUS.BankruptcyConfirmed,
    ].includes(status);

    return (
      <li>
        <input
          type='radio'
          name={value}
          value={value}
          onChange={(e) => setLegalStatus(e.target.value)}
          checked={legalStatus === value}
          disabled={isDisabled}
        />
        <label htmlFor={value} className={isDisabled ? styles.disabled : ''}>
          {label}
        </label>
      </li>
    );
  };

  return (
    <Modal isOpen={isOpen}>
      <h2>ステータスを変更</h2>
      <form onSubmit={onSubmit}>
        <section className={styles['info-section']}>
          <div className={styles.extendable}>
            <div className={styles.label}>
              {kanaName || 'カナ名がありません'}
            </div>
            <div className={cx(styles.value, styles.name)}>{kanjiName}</div>
          </div>
          <div>
            <div className={styles.label}>生年月日</div>
            <div className={cx(styles.value, styles.dob)}>
              {dateOfBirth || 'Empty'}
            </div>
          </div>
          <div>
            <div className={styles.label}>メール</div>
            <div className={cx(styles.value, styles.email)}>{email}</div>
          </div>
        </section>
        <ul className={styles['status-list']}>
          <legend>ステータス</legend>
          <LegalStatus
            label={LEGAL_STATUS_TEXT.Normal}
            value={LEGAL_STATUS.Normal}
          />
          <div>
            <LegalStatus
              label={`${LEGAL_STATUS_TEXT.LawyerInterventionAssigned}＊`}
              value={LEGAL_STATUS.LawyerInterventionAssigned}
            />
            <LegalStatus
              label={`${LEGAL_STATUS_TEXT.BankruptcyStart}＊`}
              value={LEGAL_STATUS.BankruptcyStart}
            />
            <LegalStatus
              label={`${LEGAL_STATUS_TEXT.CivilRehabilitationStart}＊`}
              value={LEGAL_STATUS.CivilRehabilitationStart}
            />
            <LegalStatus
              label={`${LEGAL_STATUS_TEXT.Reconciliation}＊`}
              value={LEGAL_STATUS.Reconciliation}
            />
            <LegalStatus
              label={`${LEGAL_STATUS_TEXT.Dead}＊`}
              value={LEGAL_STATUS.Dead}
            />
          </div>
          <div>
            <LegalStatus
              label={`${LEGAL_STATUS_TEXT.LawyerInterventionUnassigned}＊`}
              value={LEGAL_STATUS.LawyerInterventionUnassigned}
            />
            <LegalStatus
              label={`${LEGAL_STATUS_TEXT.BankruptcyConfirmed}＊＊`}
              value={LEGAL_STATUS.BankruptcyConfirmed}
            />
            <LegalStatus
              label={`${LEGAL_STATUS_TEXT.CivilRehabilitationConfirmed}＊`}
              value={LEGAL_STATUS.CivilRehabilitationConfirmed}
            />
            <LegalStatus
              label={`${LEGAL_STATUS_TEXT.FailureOfReconciliation}＊`}
              value={LEGAL_STATUS.FailureOfReconciliation}
            />
            <LegalStatus
              label={LEGAL_STATUS_TEXT.CollectionPending}
              value={LEGAL_STATUS.CollectionPending}
            />
          </div>
        </ul>

        <div className={styles.important}>
          <div>注意:</div>
          <div>
            「＊」 マークがあるステータスに変更すると、アカウントも
            Disableになります。
            <br />
            「＊＊」 マークがあるステータスに変更すると、アカウントも Close
            になります。
          </div>
        </div>

        <div className={modalStyles.actions}>
          <input
            type='submit'
            className='btn red'
            value='変更'
            data-testid='submit'
            disabled={
              loading ||
              status === LEGAL_STATUS.Dead ||
              status === LEGAL_STATUS.BankruptcyConfirmed
            }
          />
          <input
            type='button'
            className='btn'
            value='キャンセル'
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

export default UpdateLegalStatusModal;

import gql from 'graphql-tag';

export const disableConsumer = gql`
  mutation disableConsumer(
    $id: ID!
    $displayDisableNoticeToConsumer: Boolean!
  ) {
    disableConsumer(
      id: $id
      displayDisableNoticeToConsumer: $displayDisableNoticeToConsumer
    )
  }
`;

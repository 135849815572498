import gql from 'graphql-tag';

export default gql`
  query AccountPage($consumerId: ID!) {
    consumer(id: $consumerId) {
      id
      kanjiName
      status
      kanaName
      phone
      legalStatus
    }
    plusstatus(consumerId: $consumerId) {
      ekycStatus
      houkatsuStatus
      appleStatus
    }
    runningBalance(consumerId: $consumerId) {
      plusOutstandingAmount
      appleOutstandingAmount
      overdueAmount
      uncapturedAmount
      futurePlusAmount
    }
    billstatementsWithSettlements(consumerId: $consumerId) {
      billStatement {
        id
        issueDate
        amount
        unpaidAmount
        currency
        dueAt
        paidAt
        status
        lateFeesAmount
      }
      autoDebitSettlement {
        pullAmount
      }
    }
  }
`;

export const CreateImpersonatedLoginSession = gql`
  query AccountPage($consumerId: ID!) {
    createImpersonatedLoginSession(id: $consumerId) {
      id
    }
  }
`;

export const MultiPayContractsQuery = gql`
  query MultiPayContracts($consumerId: ID!) {
    multiPayContractsByConsumerId(consumerId: $consumerId) {
      applicationDatetime
      currentNumberOfInstallments
      status
      currentPrincipal
    }
  }
`;

export const ChargesQuery = gql`
  query Charges($consumerId: ID!) {
    charges(consumerId: $consumerId) {
      id
      consumerId
      pushedAt
      amount
      currency
      description
      kind
      tier
      creditLine
      metadata {
        installmentPlanKind
        installmentPlanChargeKind
        installmentPlanInstallmentNumber
        settlementMethod {
          ... on Combini {
            method
            storeName
            fee
          }
          ... on AutoDebit {
            method
          }
          ... on VAN {
            method
          }
          ... on BankTransfer {
            method
          }
          ... on Others {
            method
          }
        }
      }
    }
  }
`;
